import React from "react";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import SuccessPop from "./successPop";
import appCookie from "../../utils/cookie";
import FeedbackForm from "./feedbackForm";
import "../../../public/styles/plpContainer/plpContainer.scss";
import {
  getCookie,
  formatPrice,
  mapPaymentMethodMode,
  isMobile,
} from "../../utils/utilityManager";
import {
  imagePrefix,
  storeId,
  OrderDetailAPI,
  espotAPI,
} from "../../../public/constants/constants";
import { triggerOrderConfirmationGTEvent } from "../../utils/gtm";
import { Helmet } from "react-helmet";
import apiManager from '../../utils/apiManager';
class OrderConformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPop: false,
      orderData: "",
	    showFeedback: false,
      showPage: false,
      showGtagScript: false,
      fbPixelTriggered: false,
      orderConfirmationScripts: '',
    };
  }

   disableFeedback = () => {
     this.setState({
       showFeedback: false,
     });
   };

  fetchClickonicScriptsData() {
    apiManager
      .get(espotAPI + 'GI_B2C_Clickonic_Scripts')
      .then(response => {
        const { data } = response || {};
        //console.log(`GI_B2C_Clickonic_Scripts data orderConfirmationScripts = ${JSON.stringify(data.data.orderConfirmationScripts)}`);

        this.setState({
          orderConfirmationScripts: data && data.data.orderConfirmationScripts,
        });
      })
      .catch(error => {
        console.log("no data.");
      });
  }

  componentDidMount() {
    this.fetchClickonicScriptsData();
     const {orderId} = this.props.match.params;
     this.callOrderAPI(orderId);
     //triggerOrderConfirmationGTEvent(this.state.orderData);
   }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.orderData !== "" && prevState.orderData == "" &&
      this.state.orderConfirmationScripts !== ''
      // && prevState.orderData =="" && this.state.orderData !== ""
    ) {
      {
        this.state.orderConfirmationScripts && this.state.orderConfirmationScripts.map((scriptData, index) => {
          //console.log('orderConfirmationScripts found '+index);
          const script1 = document.createElement('script');
          if (scriptData.type == 'file') {
            script1.src = scriptData.scriptName;
            script1.async = true;
            var found = document.querySelector(`script[src="${scriptData.scriptName}"]`);
            //console.log('script in bodyTagScripts :- '+scriptData.scriptName+' is '+found);
            if (found == null) {
              document.body.appendChild(script1);
            }
            // document.body.appendChild(script1);
          } else if (scriptData.type == 'function') {
            script1.innerHTML = scriptData.scriptName;
            var functionName = scriptData.scriptName.replace('window.', '').replace('.init();', '');
            if (window[functionName] && typeof window[functionName].init === 'function') {
              //console.log('script in bodyTagScripts :- '+scriptData.scriptName+' is found');
              document.body.appendChild(script1);
            }
            // document.body.appendChild(script1);
          } else if (scriptData.type == 'jsonFunction') {
            var paramJSON = scriptData.paramJSON;
            //console.log('paramJSON = '+JSON.stringify(paramJSON));
            //console.log('orderData in jsonFunction = '+JSON.stringify(this.state.orderData));
            paramJSON.txn_id = this.state.orderData.orderID || '';
            paramJSON.sale_amount = this.state.orderData.orderSummary.netAmount || '';
            //console.log('paramJSON updated = '+JSON.stringify(paramJSON));
            var jsonString = JSON.stringify(paramJSON);
            //console.log('scriptName updated = '+scriptData.scriptName.replace('JSON', jsonString));
            script1.innerHTML = scriptData.scriptName.replace('JSON', jsonString);
            document.body.appendChild(script1);
          }

        })
      }
    }


     if (this.state.orderData !== "" && prevState.orderData == "") {
       console.log('orderData.orderItems before processing = ' + JSON.stringify(this.state.orderData.orderItems));
       var cookiesproductsBreadCrumbData = [];
       const arrayString = decodeURIComponent(appCookie.get('productsBreadCrumbData'));
       const arrayString1 = JSON.stringify(arrayString);
       // console.log('arrayString1 = '+arrayString1);

       const arrayString2 = JSON.parse(arrayString1);
       // console.log('arrayString2 = '+arrayString2);
       // console.log('arrayString2 length = '+arrayString2.length);

       // if(JSON.parse(arrayString2)!==null && JSON.parse(arrayString2)!=='null'){
       const parsedData = JSON.parse(arrayString2);
       // console.log('parsedData = '+JSON.stringify(parsedData));
       // }
       if (parsedData !== null && parsedData !== 'null' && parsedData !== "null") {
         console.log('null1');
         cookiesproductsBreadCrumbData = parsedData;
       }

       console.log('cookiesproductsBreadCrumbData in orderconfirmation = ' + JSON.stringify(cookiesproductsBreadCrumbData));
       console.log('cookiesproductsBreadCrumbData in orderconfirmation length = ' + cookiesproductsBreadCrumbData.length);

       if (cookiesproductsBreadCrumbData !== null && cookiesproductsBreadCrumbData.length > 0) {
         {
           this.state.orderData && this.state.orderData.orderItems && this.state.orderData.orderItems.map((orderItem, index) => {
             cookiesproductsBreadCrumbData && cookiesproductsBreadCrumbData.map((cookieProductsData, index) => {
               console.log('orderItem.partNumber = ' + orderItem.partNumber);
               console.log('cookieProductsData.partNumber = ' + cookieProductsData.partNumber);
               if (cookieProductsData.partNumber == orderItem.partNumber) {
                 console.log('orderItem.partNumber = ' + orderItem.partNumber + ' is matched with cookieProductsData.partNumber =' + cookieProductsData.partNumber);
                 orderItem.breadcrumbData = cookieProductsData.breadcrumbData;
               }

             })
           })

         }

       }
       console.log('orderData.orderItems after processing = ' + JSON.stringify(this.state.orderData.orderItems));
       triggerOrderConfirmationGTEvent(this.state.orderData);

     }

     // triggerOrderConfirmationGTEvent(this.state.orderData);
   }

   callOrderAPI(id) {
     this.setState({
       showPop: true,
     });

     const token = appCookie.get("accessToken");
     const url = `${OrderDetailAPI}/${id}`;
     axios
       .get(url, {
         headers: { store_id: storeId, access_token: token, profile: "summary" },
       })
       .then(res => {
         setTimeout(() => {
           this.setState({
             showPop: false,
             showFeedback: true,
           });
         }, 4000);
         this.setState({
           showPage: true,
           orderData: res.data.data,
           showGtagScript: true,
         },()=>this.pixelTrackier());
       })
       .catch(err => {
         this.props.history.push("/");
       });
   }


   pixelTrackier(){
    // if (this.state.showGtagScript && this.state.gtag) {
      window.TrackierWebSDK.trackConv('tsm.s3g6.com', '65a4e7071c56332928198b86', 
      {"is_camp":true,"txn_id":`${this.state.orderData.orderID}`,"sale_amount":`${this.state.orderData.orderSummary.netAmount}`,"is_iframe":true});
      gtag("event", "conversion", {
        send_to: "AW-880478252/AnvmCJ3FwrYBEKyQ7KMD",
        value: this.state.orderData.orderSummary.netAmount,
        currency: "INR",
        transaction_id: this.state.orderData.orderID,
      });
    // }
    try {
      if (!this.state.fbPixelTriggered && this.state.orderData && fbq) {
        fbq('track', 'Purchase', {
          currency: 'INR',
          quantity: this.state.orderData.orderItems
            .map(item => item.quantity)
            .reduce((a, b) => a + b),
          value: this.state.orderData.orderSummary.netAmount,
        });
        this.setState({ fbPixelTriggered: true });
      }
    } catch (error) {
    }
   }


  renderItems = () => {
    const items = [];
    if (this.state.orderData) {
      let exchangeIndex = []
      this.state.orderData.orderItems.map((item,index)=>{
        if(item.extendedAttributes && item.extendedAttributes.length > 0) {
          item.extendedAttributes.map((attr) => {
            if(attr.attributeName === "ExchangeDiscountAmount") {
              index%2 === 0 ? exchangeIndex.push(index+1) : exchangeIndex.push(index-1)
            }
          })
        }
      }) 
      this.state.orderData.orderItems.forEach((item,index) => {
        let exchangeValue = null 
        if(item.extendedAttributes && item.extendedAttributes.length > 0) {
          item.extendedAttributes.map((attr) => {
            if(attr.attributeName === "ExchangeDiscountAmount") {
              exchangeValue = attr.attributeValue
            }
          })
        }
        items.push(
          <div className="col-md-6 paddingRight orderItemsList">
            <div className={`itemOrderdata d-flex flex-column ${!isMobile() && !exchangeValue && exchangeIndex.includes(index) ? 'ExchangeItemOrderData' : ''}`}>
              <div className="row d-flex flex-row align-items-start">
                <div
                  className={
                    !isMobile()
                      ? "col-xs-4 col-sm-3 col-md-4"
                      : "col-xs-4 col-sm-3 col-md-4 orderItemsImg"
                  }
                >
                  <div className="itemImg">
                    <img
                      className="imgFullwidth"
                      src={
                        item.thumbnail
                          ? `${imagePrefix}${item.thumbnail}`
                          : require("../../../public/images/plpAssests/placeholder-image.png")
                      }
                      alt="itemImg"
                    />
                  </div>
                </div>

                <div
                  className={
                    !isMobile()
                      ? "col-xs-8 col-sm-9 col-md-8 borderLeft"
                      : "col-xs-8 col-sm-9 col-md-8"
                  }
                >
                  <div className={`itemText ${exchangeValue ? 'exchange-itemText' : ''}`}>
                    <h4 className="heading">{item.productName}</h4>
                    <p className="subText">{`(${item.shortDescription})`}</p>
                    <div className="row">
                      <div className="col-xs-6 col-sm-6 col-md-4 qty-details">
                        <div className="headingSubtext">
                          Quantity {isMobile() && ": "}
                        </div>
                        <div className="quantityDelivery">{item.quantity}</div>
                      </div>
                      {isMobile() && (
                        <div className="col-xs-6 col-sm-6 item-per-price">
                          ₹{formatPrice(item.offerPrice)}
                        </div>
                      )}

                      <div className="col-xs-12 col-sm-12 col-md-8 dlivery-desc">
                        <div className="headingSubtext">Delivery On</div>
                        <div className="quantityDelivery">
                          {item.deliveryDate}
                        </div>
                      </div>
                    </div>
                    {!isMobile() && (
                      <>
                        <div className="priceHeading">Price</div>
                        <div className="priceVal">
                          ₹{formatPrice(item.offerPrice)}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
              {exchangeValue && <div className="row exchange-offer-order"> 
                <p className="exchange-heading">Exchange Offer Applied</p>
                <p className="exchange-label">Exchange Value : <strong>₹{formatPrice(exchangeValue)}</strong></p>
              </div>}
            </div>
          </div>
        );
      });
      return items;
    }
  };

  onContinueShoppingBtn() {
    this.props.history.push("/");
  }

  onTrackOrderBtn() {
    getCookie("isLoggedIn") === "true" ? (
      <Link
        className="action"
        to={{ pathname: "/myAccount", state: { from: "myorder" } }}
      >
        {linkData.text}
      </Link>
    ) : (
      <Link className="action" to="/guestTrackOrder">
        {linkData.text}
      </Link>
    );
  }

  showLoader() {
    const idid = (
      <div className="lazyloading-Indicator">
        <img
          id="me"
          className="loadingImg"
          alt="Lazy Loader"
          src={require("../../../public/images/plpAssests/lazyloadingIndicator.svg")}
        />
      </div>
    );
    return idid;
  }

  render() {
    if (!this.state.showPage) {
      return this.showLoader();
    }
   
    return (
      <div className="orderconfirm">
        <Helmet>
        <script src='https://static-cdn.trackier.com/js/trackier-web-sdk.js'></script>
        <script>
        {/* window.TrackierWebSDK.trackConv('tsm.s3g6.com', '65a4e7071c56332928198b86', {"is_camp":true,"txn_id":"{{order-id}}","sale_amount":"{{order-amount}}","is_iframe":true}); */}
        </script>

        </Helmet>
        {/* <ContentEspot espotName={'GI_PIXEL_ORDERCONFIRMATION_BODY_START'} /> */}
        <div className="container">
          <div className="row">
            <div className="col-md-9">
              <div className="orderConfirmed">
                <h3 className="heading">Your order has been confirmed</h3>
                <p className="text">Thank you for shopping with us!</p>
                <p className="text">
                  We will send an email to your registered address when your
                  order is ready to be shipped.
                </p>
              </div>
            </div>
            {!isMobile() && (
              <div className="col-md-3">
                <div className="continueShopping">
                  <button
                    onClick={this.onContinueShoppingBtn.bind(this)}
                    className="btn-bg"
                  >
                    CONTINUE SHOPPING
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="orderDetails">
            <div className="trackOrdersData">
              <h4 className="heading-details">Order Details</h4>
              {this.state.orderData ? (
                <div className="row">
                  <div className="col-xs-6 col-sm-6 col-md-2">
                    <div className="heading">Order ID</div>
                    <div className="subText">
                      {this.state.orderData.orderID}
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-2">
                    <div className="heading">Order Date</div>
                    <div className="subText">
                      {this.state.orderData.orderDate}
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-3">
                    <div className="heading">Address</div>
                    <div className="subText">
                      {this.state.orderData.address.address1}
                      <br />
                      {this.state.orderData.address.address2}
                      <br />
                      {this.state.orderData.address.address3 && (
                        <>
                          {this.state.orderData.address.address3}
                          <br />
                        </>
                      )}
                      {this.state.orderData.address.city},{" "}
                      {this.state.orderData.address.state},{" "}
                      {this.state.orderData.address.pincode}
                    </div>
                  </div>
                  <div className="col-xs-6 col-sm-6 col-md-2">
                    <div className="heading">Payment Method</div>
                    <div className="subText">
                      {mapPaymentMethodMode(this.state.orderData.paymentMethod)}
                    </div>
                  </div>
                  {!isMobile() && (
                    <div className="col-md-3">
                      <div className="heading">Total Amount</div>
                      <div className="totalAmount">
                        ₹
                        {formatPrice(
                          this.state.orderData.orderSummary.netAmount
                        )}
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>

            {getCookie("isLoggedIn") === "true" ? (
              <div className="trackOrderBtnBox">
                <Link
                  className="action"
                  to={{ pathname: "/myAccount", state: { from: "myorder" } }}
                >
                  <button className="btn-bg btntrackorder">TRACK ORDER</button>
                </Link>
              </div>
            ) : (
              <div className="trackOrderBtnBox">
                <Link className="action" to="/guestTrackOrder">
                  <button className="btn-bg btntrackorder">TRACK ORDER</button>
                </Link>
              </div>
            )}

            {!isMobile() && <div className="seprator" />}
            <div className="orderItemSummary">
              <h4 className="heading-details">Items in Order</h4>
              <div className="row">
                {this.state.orderData ? this.renderItems() : ""}
              </div>
            </div>

            {isMobile() && (
              <div className="row track-order-total">
                {this.state.orderData && (
                  <div className="order-price-details">
                    <div className="heading">Total:</div>
                    <div className="totalAmount">
                      ₹
                      {formatPrice(this.state.orderData.orderSummary.netAmount)}
                    </div>
                  </div>
                )}

                <div className="continueShopping">
                  <button
                    onClick={this.onContinueShoppingBtn.bind(this)}
                    className="btn-bg"
                  >
                    CONTINUE SHOPPING
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {this.state.showFeedback ? (
          <FeedbackForm
            disableFeedback={this.disableFeedback.bind(this)}
            orderData={this.state.orderData}
          />
        ) : (
          ""
        )}
        {this.state.showPop ? <SuccessPop /> : ""}
        {/* <SuccessPop /> */}
        {/* <ContentEspot espotName={'GI_PIXEL_ORDERCONFIRMATION_BODY_END'} /> */}
      </div>
    );
  }
}

export default withRouter(OrderConformation);
