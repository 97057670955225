import React, { Suspense } from "react";
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
import injectSaga from '../../utils/injectSaga';
import injectReducer from '../../utils/injectReducer';
import {
  getReleventReduxState,
  isMobile,
  formatPrice,
  createSEOPdpURL,
} from '../../utils/utilityManager';
import reducer from './reducer';
import saga from './saga';
import appCookie from '../../utils/cookie';
import {getCookie} from '../../utils/utilityManager';
import LoadingIndicator from '../../utils/loadingIndicator';
import apiManager from '../../utils/apiManager';
import { getCartDetails } from './action';
import { imagePrefix,espotAPI,findinventoryAPI, cartUpdateAPI, addToCart,cartDetailAPI,validateDealerCodeAPI, removeExchangeAPI, toggleExchange } from '../../../public/constants/constants';
import EmptyCart from '../../components/Cart/emptyCart';
import Pincode from '../../components/Cart/pincode';
import CartUpdate from '../../components/Cart/updateCart';
import DeleteCartItem from '../../components/Cart/cartDeleteItem';
import MoveToWishList from '../../components/Cart/moveToWishList';
import '../../../public/styles/cart/cartItem.scss';
import GetCartPromo from '../../components/Cart/promotion';
import PromoField from '../../components/Cart/applyPromo';
import MWebLogo from '../../components/SVGs/mWebLogo';
import AppliedPromoCode from '../../components/Cart/appliedPromoCode';
// import EmiInfo from '../../components/PdpComponent/emiInfo';
import ExpandIcon from '../../components/SVGs/expandArrow';
import OutOfStockIcon from '../../components/SVGs/outOfStockIcon';
import EMIVal from '../../components/Cart/emiPrice';
import ContentEspot from '../../components/Primitives/staticContent';
import Pixels from '../../components/Primitives/pixels';
import { cartDeleteItemAPI, addToWishlist } from '../../../public/constants/constants';
import {
  YOUR_CART,
  DO_YOU_HAVE_COUPAN,
  ORDER_SUMMARY,
  CART_TOTAL,
  PRODUCT_DISCOUNT,
  ORDER_DISCOUNT,
  ADDITIONAL_DISCOUNT,
  SHIPPING,
  TOTAL,
  PROCEED_TO_CHECK_OUT,
  FREE,
  DELIVERY_BY,
  SHIPPING_CHARGES,
  TOTAL_AMOUNT,
  ITEMS,
  EXCHANGE_DISCOUNT,
} from '../../constants/app/cartConstants';
import { triggerReviewCartGTEvent } from '../../utils/gtm';
const BundleMainCart = React.lazy(() => import('../../components/Cart/Bundling/BundleMainCart'));
import Tooltip  from 'react-bootstrap/lib/Tooltip';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
const regexDealerCode = /^[a-zA-Z0-9]+$/;

class CartDetail extends React.Component {
  constructor(props) {
    super(props);
    this.textInput = React.createRef();
    this.state = {
      showReply: true,
      abc: false,
      congratstext_01: '',
      congratstext_02: '',
      congratstext_border: '',
      congratstext_color: '',
      discount_color: '',
      espotName: 'GI_CheckoutJourney',
      bundleLoad: false,
      bundlingData:[],
      warrantyInventory: [],
      Loading: false,
      dealerCode:'',
      disableCheckout:false,
      error:false,
      errorMsg:'',
      dealerEspotName: 'GI_B2C_DealerRelationshipCode',
      instoreEmployeeText: '',
      instoreEmployeeDesc: '',
      instoreEmployeePlaceholder: '',
      warButton: false,
      pincode: appCookie.get('pincode'),
      cartCouponsBlock: [],
      promocodeError: '',
      validationErrorMsg: '',
      TokenGenerationAPI: '',
      TokenGenerationHeader: '',
      TokenGenerationBody: '',
      dealerCodeVerificationAPI1: '',
      dealerCodeVerificationAPI2: '',
      dealerCodeValidationErrorMsg: '',
      dealercodetextclassName:'',
            strikethroughText:'',
   }
   this.handleDealerCode = this.handleDealerCode.bind(this);
   this.addToWishlistApi = this.addToWishlistApi.bind(this);
  }


  getEspotData() {
    apiManager
      .get(espotAPI + this.state.espotName)
      .then(response => {
        const {data} = response || {};
        // console.log(`data = ${JSON.stringify(data.data.congratstext_01)}`);

        this.setState({
          congratstext_01: data && data.data.congratstext_01,
          congratstext_02: data && data.data.congratstext_02,
          congratstext_border: data && data.data.congratstext_border,
          congratstext_color: data && data.data.congratstext_color,
          discount_color: data && data.data.discount_color,
          cartCouponsBlock: data && data.data.cartCouponsBlock,
          promocodeError: data && data.data.promocodeError,
                    strikethroughText: data && data.data.strikethroughText,
        });
      })
      .catch(error => {
        console.log("no data.");
      });
  }

  getDealerEspotData() {
    apiManager
      .get(espotAPI + this.state.dealerEspotName)
      .then(response => {
        const {data} = response || {};
        // console.log(`data = ${JSON.stringify(data.data.congratstext_01)}`);

        this.setState({
          instoreEmployeeText: data && data.data.instoreEmployeeText,
          instoreEmployeeDesc: data && data.data.instoreEmployeeDesc,
          instoreEmployeePlaceholder: data && data.data.instoreEmployeePlaceholder,
          validationErrorMsg: data && data.data.validationErrorMsg,
          TokenGenerationAPI: data && data.data.TokenGenerationAPI,
          TokenGenerationHeader: data && data.data.TokenGenerationHeader,
          TokenGenerationBody: data && data.data.TokenGenerationBody,
          dealerCodeVerificationAPI1: data && data.data.dealerCodeVerificationAPI1,
          dealerCodeVerificationAPI2: data && data.data.dealerCodeVerificationAPI2,
        });
      })
      .catch(error => {
        console.log("no data.");

        this.setState({

        });
      });
  }

    handleDealerCode(event) {
    this.setState({ dealerCode: event.target.value,
      error:false,
      errorMsg:'',
      dealerCodeValidationErrorMsg:'',
      dealercodetextclassName:'',
    });
  }

  async validateDealerCode(event) {
    this.setState({
      Loading:true,
    });
    if(this.state.dealerCode==''){
      this.updateDealerCode();
    }else{
    const dealerCodeData = {
      dealerCode:this.state.dealerCode,
      TokenGenerationAPI: this.state.TokenGenerationAPI,
      TokenGenerationHeader: this.state.TokenGenerationHeader,
      TokenGenerationBody: this.state.TokenGenerationBody,
      dealerCodeVerificationAPI: this.state.dealerCodeVerificationAPI1+this.state.dealerCode+this.state.dealerCodeVerificationAPI2,
    }
    // console.log('dealerCodeData = '+JSON.stringify(dealerCodeData));
    var validateDealerCodeStatus='';
      await apiManager.post(validateDealerCodeAPI, dealerCodeData,{
        timeout: 30000, // milliseconds (e.g., 10 seconds)
      }).then((resp)=>{
        if(resp){
          if(resp && resp.data && resp.data.data && resp.data.data.data && resp.data.data.data.employeeStatus){
            validateDealerCodeStatus=resp.data.data.data.employeeStatus;
          }
          if(validateDealerCodeStatus=='Valid'){
            this.updateDealerCode();
          }else{
            this.setState({
            dealerCodeValidationErrorMsg:this.state.validationErrorMsg,
            dealercodetextclassName:'dealer-code-text-section2',
            Loading:false,
            })
            this.textInput.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }
      }).catch((err)=>{
        this.updateDealerCode();
      })
    

    }
  }

  updateDealerCode=async()=>{
  await this.updateInitialWarQty(true)
  let resp = await  apiManager.get(cartDetailAPI, { 'headers': { 'pincode': appCookie.get('pincode') } })
  const cartData = resp.data.data.cartItems;
   triggerReviewCartGTEvent(resp.data.data)
  const data = {
    orderItem: [
       {
         quantity: cartData[0].quantity,
         orderItemId: cartData[0].orderItemId
       },
     ],
     orderExtendAttribute:[
      {attributeValue:this.state.dealerCode,
      attributeType:"STRING",
      attributeName:"STOREREPCODE"
    }
      ]
   };
   let error=false;
  {
    (regexDealerCode.test(this.state.dealerCode)==false) ?
    error=true
     :
    error=false
  }
  {this.state.dealerCode!=''?
  (error==false?

   apiManager
     .post(cartUpdateAPI, data)
     .then(response => {
      !this.state.disableCheckout && response.data.status=="success"?
        window.location.href='/checkout' : ''
      
     })
     .catch(error => {
       this.setState({
         error,
         isLoading: false,
         maxQty: error.response && error.response.data.error.error_message,
       });
      
     })
     :
     this.setState({ error: true, errorMsg: 'Please enter only numbers or letters for dealercode' })
            )
     :
     !this.state.disableCheckout?
      window.location.href='/checkout' : ''
    
    }


}

  loadBundleData=async()=>{
    // alert('hii')
    this.checkWarrantyInventory()

    // if(this.props.cartData){
    //   const cartProducts = []
    //   const pincode = appCookie.get('pincode');
    //   this.props.cartData.cartItems.map((cartItem,key)=>{
    //     cartProducts.push(cartItem.uniqueID)
    //   })
    //   const bundleData = []
    //   for(let i=0;i<this.props.cartData.cartItems.length;i++)
    //   {
    //     const cartItem = this.props.cartData.cartItems[i]
    //     // this.props.cartData.cartItems.map((cartItem,key)=>{
    //     if(!(bundleData.length >= 2))
    //       if(cartItem.bundleData.length > 0){
    //         for(let j=0; j<cartItem.bundleData.length; j++)
    //         // cartItem.bundleData.map((prod,key)=>{
    //         {
    //           const prod = cartItem.bundleData[j]
    //           // console.log(bundleData.length)
    //           if(!(bundleData.length >= 2)){
    //           // console.log(prod)
    //             if(!cartProducts.includes(prod.uniqueID)){
    //               const Inventorydata = this.getInventoryApiParams(prod, 1);
    //               const response = await apiManager.get(findinventoryAPI + pincode, Inventorydata)
    //               if(response.data.data.inventoryStatus === 'available'){
    //                 bundleData.push(prod)
    //                 // console.log(prod)
    //               }
    //             }
    //           }
    //         }
    //       }
    //   }
    //   // })
    //   this.setState({
    //     bundlingData: bundleData,
    //     bundleLoad: true,
    //   })
    // }
    
  }

  getInventoryApiParams(resolvedSkuData, getQuantity) {
    const partnumber = [];
    const quantity = [];
    let dataParams;
    
    if (resolvedSkuData.itemInThisBundle) {
      resolvedSkuData.itemInThisBundle.map((data) => {
        partnumber.push(data.partNumber);
        quantity.push(data.quantity * getQuantity);
				 dataParams = {
          params: {
            partnumber: partnumber.toString(),
					  quantity: quantity.toString(),
          },
        };
      })
    } else {
      dataParams = {
        params: {
          partNumber: resolvedSkuData.partNumber,
          quantity: getQuantity,
        },
      };
    }
    return dataParams;
  }

  checkboxWarranty =(value, name, id, checked) =>{
    // e.target.id -> warranty sku
    // e.target.name -> item SKU
    // const id = e.target.id;
    // const targetName = e.target.name;
    this.setState({
      warButton: true
    })
    this.props.cartData.cartItems.map(async(item,index)=>{
     if(item.partNumber == name){
      
      let mainProd = item.quantity
        if(value==''){
          // add warranty to cart
          const pincode = appCookie.get('pincode');
          let InvenData
          this.setLoading()
          if(item.accessoryData.length>0){
            item.accessoryData.map((accData)=>{
              if(accData.uniqueID==id){
                InvenData = accData
              }
            })
          }
          const Inventorydata = this.getInventoryApiParams(InvenData, 1);
        await apiManager.get(findinventoryAPI + pincode, Inventorydata)
        .then(async response=>{
             if(response.data.data.inventoryStatus === 'available'){
                 const data = {
           orderItem: [
             {
                quantity: item.quantity,
                orderItemId: item.orderItemId,
                orderItemExtendAttribute:
                [
                  {
                    attributeName:"ExtendedWarrantyCatID",
                    attributeValue: id,
                  },
                ],
              },
            ],
          };
          await apiManager
            .post(cartUpdateAPI, data)
            .then(async response => {
              // console.log(response)
              if(response.data.status =='success'){
              
                const addWarrData = {
                  orderItem: [
                    {
                      sku_id: id,
                      quantity:  `${item.quantity}`,
                      orderItemExtendAttribute: [
                        {
                          attributeName:"isCostItem",
                          attributeValue: "Y",
                        },
                      ],
                    },
                  ],
                };
              await  apiManager
                  .post(addToCart, addWarrData)
                  .then(async (resp) => {
                  try{
                    let resp = await this.props.getCartDetails()}
                    catch(err){
                      console.log(err)
                    }
                  }
                   )
                  .catch(error => {
                    setTimeout(() => {
                      this.setLoadedTrue()
                    }, 1000);
                  });
              }else{
                this.setLoadedTrue()
                this.setState({
                  warButton: false
                })
             }
            
           })
            .catch(error => {
              this.setState({
                error,
                isLoading: false,
                maxQty: error.response && error.response.data.error.error_message,
              }
              );
              setTimeout(() => {
                this.setLoadedTrue()
              }, 1000);
              this.updateInitialWarQty()
            });
            // this.props.getCartDetails()
             }
            else{
              this.setLoadedTrue()
              this.setState({
                checked: false,
                warButton: false
             })
           }
          })
         }
       else{
          // remove warranty from cart
          this.setLoading()
          this.props.cartData.cartItems.map(async(warData,index)=>{
            if(warData.uniqueID == id){
             
             const data = {
                orderItem: [
                  {
                    quantity: `${item.quantity}`,
                    orderItemId: item.orderItemId,
                    orderItemExtendAttribute:
                    [
                      {
                        attributeName:"ExtendedWarrantyCatID",
                        attributeValue: '',
                      },
                    ],
                  },
                  {
                    quantity: warData.quantity - item.quantity <=0? `${0}`:  `${warData.quantity - item.quantity}`,
                    orderItemId: warData.orderItemId,
                  }
                ],
              };
             await apiManager
              .post(cartUpdateAPI, data)
              .then(response => {
                this.props.getCartDetails()
              })
           }
          })
       }
     }
    })
  }


  getInventoryApiParams(resolvedSkuData, getQuantity) {
    const partnumber = [];
    const quantity = [];
    let dataParams;
    
    if (resolvedSkuData.itemInThisBundle) {
      resolvedSkuData.itemInThisBundle.map((data) => {
        partnumber.push(data.partNumber);
        quantity.push(data.quantity * getQuantity);
				 dataParams = {
          params: {
            partnumber: partnumber.toString(),
					  quantity: quantity.toString(),
          },
        };
      })
    } else {
      dataParams = {
        params: {
          partNumber: resolvedSkuData.partNumber,
          quantity: getQuantity,
        },
      };
    }
    return dataParams;

  }


  componentWillReceiveProps(nextProps){
     if(this.state.pincode != appCookie.get('pincode')){
      this.setState({
        pincode: appCookie.get('pincode'),
        warrantyInventory: []
      },()=>{
        this.checkWarrantyInventory()})
    }
    if(nextProps.cartData != this.props.cartData){
      this.setState({
        warButton: false
      })
      setTimeout(()=>{
        this.setLoadedTrue()
      },500)
      
      
    }
  }

  updateCartFromBundle(selectedProducts){
    this.props.getCartDetails();
    if(this.state.bundlingData.length>0){
      const newBundle = []
      this.state.bundlingData.map((prod,key)=>{
        for(let i =0; i<selectedProducts.length; i+=1)
        {
          if(newBundle.length == 2){
            return
          }
          if(prod.uniqueID!=selectedProducts[i].uniqueID ){
            newBundle.push(prod)
            break
          }
        }
      })
      this.setState({
        bundlingData: newBundle.splice(0,2),
      },()=>{
      })
    } 
  }
  
  checkWarrantyInventory(){
    if( this.props.cartData){
      if(this.props.cartData.cartItems){
        this.props.cartData.cartItems.map((itemData,index)=>{
          if(itemData.accessoryData && itemData.accessoryData.length>0){
            const pincode = appCookie.get('pincode');
            const Inventorydata = this.getInventoryApiParams(itemData.accessoryData[0], 1);
            apiManager.get(findinventoryAPI + pincode, Inventorydata).then((resp)=>{
              if(resp.data.data.inventoryStatus==='available'){
                this.setState({
                  warrantyInventory:[...this.state.warrantyInventory, index]
                })
              }
            })
          }
        })
      }
     
    }
    
  }


  updateInitialWarQty=async(firstLoad = false)=>{
    
    await apiManager.get(cartDetailAPI, { 'headers': { 'pincode': appCookie.get('pincode') } }).then(async(resp)=>{
    let cartData = resp.data.data
    if(cartData){
      if(cartData.cartItems){
      let warList= {}
      let orderItem = []
      let warAmt = 0
      let tempData = []
      // if(firstLoad){
      //   console.log('FIRST LOAD')
        
      //   let data = {
      //     orderItem: [
      //       {
      //         quantity: cartData.cartItems[1].quantity,
      //         orderItemId: cartData.cartItems[0].orderItemId,
      //         // cartQty:this.returnQtyValues(qty),
      //       },
      //     ],
      //   };
      //   await apiManager.post(cartUpdateAPI, data).then((resp)=>{
      //     if(resp){
      //       // this.setLoadedTrue()
      //       this.props.getCartDetails()
      //    }
      //   }).catch((err)=>{
      //     this.props.getCartDetails()
      //    })
      // }
      this.setLoading()
          for(let index=0; index<cartData.cartItems.length; index+=1){
            let prod = cartData.cartItems[index]
            tempData.push({quantity: cartData.cartItems[index].quantity , orderItemId: cartData.cartItems[index].orderItemId})
            if(prod.addWarrantySKU != ''){
              if(prod.productName && prod.productName.includes('Warranty')){
                // console.log('here')
                warAmt=1+warAmt
              }
              if(warList[prod.addWarrantySKU]){
                warList[prod.addWarrantySKU] = warList[prod.addWarrantySKU] + prod.quantity
              }
              else{
                warList[prod.addWarrantySKU]= prod.quantity
              }
            }else if(prod.productName && prod.productName.includes('Warranty')){
              warAmt=1+warAmt
            }
           // console.log(warAmt)
          }
          
        // })
       if(firstLoad){
        let temp = {orderItem: tempData}
        await apiManager.post(cartUpdateAPI, temp).then((resp)=>{
          if(resp){
            // this.setLoadedTrue()
            this.props.getCartDetails()
         }
        }).catch((err)=>{
          this.props.getCartDetails()
         })
       }
       if(warAmt == cartData.cartItems.length){
          for(let indx=0; indx< cartData.cartItems.length; indx+=1){
              orderItem.push({
                quantity: `0`,
                orderItemId: cartData.cartItems[indx].orderItemId,
              })
            // })
          }
         
        }else{
          cartData.cartItems.map((prod,index)=>{
            if(prod.productName && prod.productName.includes('Warranty')){
              if(prod.quantity == warList[prod.uniqueID]){
              }
              else{
                if(warList[prod.uniqueID] != undefined){
                  orderItem.push({
                    quantity: `${warList[prod.uniqueID]}`,
                    orderItemId: prod.orderItemId,
                  })
                }
              }
            }
          })
        }
       
       if (orderItem==[] || orderItem.length==0){
          this.props.getCartDetails()
           return
        }
        else{
          let data = {
            orderItem: orderItem
          }
          // this.setLoading()
         await apiManager.post(cartUpdateAPI, data).then((resp)=>{
          if(resp){
            // this.setLoadedTrue()
            this.props.getCartDetails()
         }
        }).catch((err)=>{
          this.props.getCartDetails()
         })
        }
      }
     
    }})
   }



  componentDidMount() {
    if(!window.top.location.href.includes('cart')){
      window.top.location.assign('/cart');
    }
    this.getEspotData();
    this.getDealerEspotData();
    this.props.getCartDetails()
    if(getCookie('isLoggedIn') === 'true' && getCookie('wishListUniqueId') !== undefined && getCookie('wishListUniqueId') !== null && getCookie('wishListUniqueId') !== ''){
      this.addToWishlistApi();
    }
    this.updateInitialWarQty()
    // triggerReviewCartGTEvent(this.props.cartData);
  }

  componentDidUpdate(prevProps, prevState) {
    // triggerReviewCartGTEvent(this.props.cartData);
  }

  handleOnClick(e) {
    e.preventDefault();
    this.setState({ showReply: !this.state.showReply })
  }

  addToWishlistApi(){
    const wishlistId = appCookie.get('wishListUniqueId')
    const data = {
      sku_id: wishlistId,
    };
    apiManager
      .post(addToWishlist, data)
      .then(response => {
        this.handleDeleteItem();
        // appCookie.set('wishListUniqueId', '' , 365 * 24 * 60 * 60 * 1000);
        // appCookie.set('saveForLaterClicked', false , 365 * 24 * 60 * 60 * 1000);
      }).catch(error => {
				
      });
  }

  handleDeleteItem() {
    const orderId = appCookie.get('orderItemId');
    	const data = {
    	orderItemId: orderId,
    	};
    	apiManager
    	.post(cartDeleteItemAPI, data)    	
      .then(response => {
        appCookie.set('wishListUniqueId', '' , 365 * 24 * 60 * 60 * 1000);
        appCookie.set('saveForLaterClicked', false , 365 * 24 * 60 * 60 * 1000);
        this.setState({abc: true});
      })
    	.catch(error => {
    		
    	});
  }

  updateBundleProductsDisplay(uniqueID, productData){
    let productExist = false
    const {bundlingData} = this.state
    if(this.state.bundlingData.length>0){
      this.state.bundlingData.reverse().map((product,key)=>{
        if(product.uniqueID==uniqueID){
          productExist = true
          bundlingData.splice(key,1)
        }
      })
      bundlingData.push(productData)
      this.setState({
        bundlingData:bundlingData.reverse().splice(0,2),
      })
      // console.log()
    }
    else{
      this.setState({
        bundlingData: [productData],
      })
    }
  }

  setLoading(){
    this.setState({
      Loading: true
    })
  }

  setLoadedTrue(){
    // alert('here')
    this.setState({
      Loading: false
    },()=>{
      this.setState({
        warButton: false
      })
    })
  }

  handleRemoveExchange (id) {
    this.setLoading()
    const reqBody ={
      "productId": parseFloat(id.uniqueID),    
      "ExchangeApplied" : "false",
      "orderId": parseFloat(this.props.cartData.orderSummary.orderID) ,
      "orderItemId": parseFloat(id.orderItemId) 
  }
    apiManager.post(toggleExchange,reqBody)
    .then((result)=>{
      result.data && result.data.status==="success" && this.props.getCartDetails()
    })
    .catch((err)=>{ })
  }

  render() {
    const { cartData } = this.props;
    if (!cartData) return <LoadingIndicator/>;
    let disableCheckout = false;


    const isSaveForLater = appCookie.get('saveForLaterClicked');
    const wishListId = appCookie.get('wishListUniqueId');
    if (isSaveForLater === 'false' && wishListId !== '') {
      // this.addToWishlistApi();
      return (
        <>
        </>
      );
    }

    const tooltipDealerRelationshipDesc = <Tooltip id='tooltipDealerRelationshipDesc' className="tooltipDealerRelationshipDesc">
    <span className="instore-employee-section-text">{this.state.instoreEmployeeDesc}</span>
    </Tooltip>

    return (
      !!cartData.cartItems && !!cartData.cartItems.length
        ?
        <>
          {/* <ContentEspot espotName = { 'GI_PIXEL_CART_BODY_START' } /> */}
          <section className='cartDetails'>
          {this.state.Loading ? <div className="loading-cart"><LoadingIndicator /></div> :'' }
            <Pixels espotName= "GI_PIXEL_CART_META"/>
            <div className='cartItem'  onLoad={this.loadBundleData.bind(this)}>
               <div className='cartHeadDetails'>
                {!isMobile() ? <h2 className='title'>{YOUR_CART}<span className='cartCount'>{cartData.cartTotalItems} {ITEMS}</span>
                </h2> : <div className='checkout-top-hedaer'>
                  <a href="/" className='mob-header-logo'><MWebLogo width="24" height="24" /></a>
                  <h2 className='title'>{YOUR_CART}<span className='cartCount'>({cartData.cartTotalItems} {ITEMS})</span></h2></div> }
              	<Pincode
			  		getCartDetails={this.props.getCartDetails}
                />
              </div>
              { formatPrice(Math.round(cartData.orderSummary.saving))!=0?
                <div className='Congratulations-saving' style={{border: this.state.congratstext_border}}>
                  <center className='Congratulations-saving-text' style={{color: this.state.congratstext_color}}> {this.state.congratstext_01} <b>₹{formatPrice(Math.round(cartData.orderSummary.saving))}</b> {this.state.congratstext_02}</center>
                </div>
                :''}
              <ul className='cartItemList'>
                {cartData.cartItems.map((itemData, index) => {
                  const outOfStock = itemData.inventoryStatus !== 'available';
                  disableCheckout = !disableCheckout ? outOfStock : disableCheckout;
                  this.state.disableCheckout=disableCheckout;
                  return (
                    <>
                      {itemData.productName && itemData.productName.split(' ').includes('Warranty')? <></>:
                        <>
                          <li className={`prodList${outOfStock ? ' outOfStockList' : ''}`} key={`${index}-pro`}>
                            {outOfStock && <div className='outOfStock' />}
                            <Link to={createSEOPdpURL(itemData.productName, itemData.shortDescription, itemData.partNumber)}>
				  <figure className='prodImg'>
                                <img className='img' src={`${imagePrefix}${itemData.thumbnail}`} alt={index} />
                                {outOfStock && <div className='outOfStockLogo'><OutOfStockIcon /></div>}
                              </figure>
                            </Link>
                            <div className='prodDetails'>
                              <h3 className='prodTitle'>{itemData.productName}</h3>
                              <p className='subTitle'>({itemData.shortDescription})</p>
                              {!isMobile() && !itemData.freeGift &&
                      <CartUpdate
                        quantity={itemData.quantity}
                        orderItemId={itemData.orderItemId}
                        getCartDetails={this.props.getCartDetails}
                        cartData= {cartData}
                        itemData = {itemData}
                        updateInitialWarQty = {this.updateInitialWarQty.bind(this)}
                        setLoading = {this.setLoading.bind(this)}
                        setLoadedTrue = {this.setLoadedTrue.bind(this)}
                      />}
                              {!itemData.freeGift && 
               <MoveToWishList
                 uniqueID={itemData.uniqueID}
                 orderItemId={itemData.orderItemId}
                 getCartDetails={this.props.getCartDetails}
                 itemData = {itemData}
                 cartData = {cartData}
                 setLoading = {this.setLoading.bind(this)}
                 setLoadedTrue = {this.setLoadedTrue.bind(this)}
                 orderId = {cartData.orderSummary.orderID}
               />}
                              {!itemData.freeGift && 
                      <DeleteCartItem 
                        orderItemId={itemData.orderItemId}
                        uniqueID={itemData.uniqueID}
                        productName = {itemData.productName}
                        getCartDetails={this.props.getCartDetails}
                        cartData = {cartData}
                        itemData = {itemData}
                        updateBundleProductsDisplay = {this.updateBundleProductsDisplay.bind(this)}
                        setLoading = {this.setLoading.bind(this)}
                        setLoadedTrue = {this.setLoadedTrue.bind(this)}
                        orderId = {cartData.orderSummary.orderID}
                      />}
                              <p className='delBy'>{DELIVERY_BY}</p>
                              <span className='date'>{itemData.deliveryDate}</span>
                              { !itemData.freeGift ?
                             parseInt(itemData.totalAdjustment) == 0 ?
                                !isMobile() &&  <span className='price'>₹{formatPrice(Math.round(itemData.offerPrice)*parseInt(itemData.quantity))}</span>: !isMobile() && <span className='price price-new'>{Math.round(parseInt(itemData.totalAdjustment) + parseInt(itemData.offerPrice)*parseInt(itemData.quantity)) < 10 ? '' : <del>₹{formatPrice(Math.round(itemData.offerPrice)*parseInt(itemData.quantity))}</del>}<span className="price-discount">₹{formatPrice(Math.round(parseInt(itemData.totalAdjustment) + parseInt(itemData.offerPrice)*parseInt(itemData.quantity)))}</span></span> : <span className='price'>{FREE}</span>}
                             {!itemData.freeGift ? 
                               !isMobile() && <span className='shipping'>{SHIPPING_CHARGES} {itemData.shippingCharges === 0 ? `${FREE}` : itemData.shippingCharges}</span> : ""}
                            </div>
                            {!!isMobile() && !itemData.freeGift && <div className='quantityPrice'>
                              {!itemData.freeGift &&
						<CartUpdate
						  quantity={itemData.quantity}
						  orderItemId={itemData.orderItemId}
						  getCartDetails={this.props.getCartDetails}
						  itemData = {itemData}
              updateInitialWarQty = {this.updateInitialWarQty.bind(this)}
              setLoading = {this.setLoading.bind(this)}
              setLoadedTrue = {this.setLoadedTrue.bind(this)}
              cartData= {cartData}
						/>}
            {parseInt(itemData.totalAdjustment) == 0 ?
            <p className='price'>₹{formatPrice(Math.round(itemData.offerPrice)*parseInt(itemData.quantity))}</p>
            :
            <p className='price price-new'>{Math.round(parseInt(itemData.totalAdjustment) + parseInt(itemData.offerPrice)*parseInt(itemData.quantity)) < 10 ? '' : <del>₹{formatPrice(Math.round(itemData.offerPrice)*parseInt(itemData.quantity))}</del>}<span className="price-discount">₹{formatPrice(Math.round(parseInt(itemData.totalAdjustment) + parseInt(itemData.offerPrice)*parseInt(itemData.quantity)))}</span></p>
            }
                 
                           </div>}
                           {itemData.exchangeDiscount && <div className={`exchange-offer-container ${isMobile() ? 'exchange-offer-mobile' : ''}`}>
                              <input className="checked" disabled checked type="checkbox" />
                              <div className="exchange-body">
                                <p className="exchange-offer-heading">Exchange Offer</p>
                                <div className="exchange-offer-header">
                                  <p>Exchange value applied: <span className="exchange-value"><strong>₹{formatPrice(Math.round(itemData.exchangeDiscount))}</strong></span></p>
                                  <button onClick={ ()=>{this.handleRemoveExchange(itemData)}} className="close-button">REMOVE</button>
                                </div>
                              </div>
                            </div> 
                            }
                         </li>
         
                          <>
                            {/* Additional warranty */}

                            {itemData.addWarrantySKU != '' ?  cartData.cartItems.map((acc,key)=>{
                              if(acc.uniqueID == itemData.addWarrantySKU)
                                return(<li className={`prodListWarranty prodList${outOfStock ? ' outOfStockList' : ''}`} key={`${index}-pro`}>
                                  <div className='prodImg' checked={itemData.addWarrantySKU != '' ? true:false} 
                                  // onClick={this.checkboxWarranty.bind(this)}
                                  >
                                     <input
                                      checked={itemData.addWarrantySKU != '' ? true:false} value={itemData.addWarrantySKU} 
                                      // onChange={this.checkboxWarranty.bind(this)}
                                      defaultChecked={itemData.addWarrantySKU != ''} name={itemData.partNumber} id={itemData.addWarrantySKU} className='bundle-checkbox' type='checkbox' />
                                      <label for={itemData.addWarrantySKU}>
                                         <img className='img' src={`${imagePrefix}${acc.thumbnail}`}  alt={index} />
                                    </label>
                                  </div>
                                  <div className='prodDetails'>
                                    <h3 className='prodTitle'>{acc.productName}</h3>
                                    <p className='subTitle'>({acc.shortDescription})</p>
                                    { !acc.freeGift ?
                                      !isMobile() && 
                    <> <div className="cartQty">
                      <label className="qytLabel">{!isMobile() ? "QUANTITY"  : 'QUANTITY: '}</label>
                      <div>&nbsp;&nbsp;&nbsp;&nbsp;{itemData.quantity}</div>
                      {/* <span></span> */}
                      {parseInt(acc.totalAdjustment) == 0 ?
                                !isMobile() &&  <span className='price'>₹{formatPrice(Math.round(acc.offerPrice)*parseInt(itemData.quantity))}</span>: !isMobile() && <span className='price price-new'><del>₹{formatPrice(Math.round(acc.offerPrice)*parseInt(itemData.quantity))}</del><span className="price-discount">₹{formatPrice(Math.round(parseInt(acc.totalAdjustment)/parseInt(acc.quantity)*parseInt(itemData.quantity) + parseInt(acc.offerPrice)*parseInt(itemData.quantity)))}</span></span>}
                   </div></> : 
                                     <div className="cartQty">
                                        <label className="qytLabel">{!isMobile() ? "QUANTITY"  : 'QUANTITY: '}</label>
                                        <span>{itemData.quantity}</span>
                                        <span className='price'>{FREE}</span>
                                      </div>}
                                      <button className="add-war-btn" disabled={this.state.warButton} onClick={()=>{this.checkboxWarranty(itemData.addWarrantySKU, itemData.partNumber, itemData.accessoryData[0].uniqueID, itemData.addWarrantySKU != ''? false:true)}}>{this.state.warButton ? 'Removing' : 'REMOVE'}</button>
                                    {/* <span className='price'>₹{Math.round(acc.offerPrice)}</span> : <span className='price'>{FREE}</span>} */}
                                    {!acc.freeGift ? 
                                      !isMobile() && <span className='shipping'>{SHIPPING_CHARGES} {1 ? `${FREE}` :acc.shippingCharges}</span> : ""}
                                  </div>
                                  {!!isMobile() && !acc.freeGift && <div className='quantityPrice'>
                                    <div className="cartQty">
                                      <label className="qytLabel">{!isMobile() ? "QUANTITY"  : 'QUANTITY: '}</label>
                                      <span>{itemData.quantity}</span>
                                    </div>
                                    {parseInt(acc.totalAdjustment) == 0 ?
            <p className='price'>₹{formatPrice(Math.round(acc.offerPrice)*parseInt(itemData.quantity))}</p>
            :
            <p className='price price-new'><del>₹{formatPrice(Math.round(acc.offerPrice)*parseInt(itemData.quantity))}</del><span className="price-discount">₹{formatPrice(Math.round(parseInt(acc.totalAdjustment)/parseInt(acc.quantity)*parseInt(itemData.quantity) + parseInt(acc.offerPrice)*parseInt(itemData.quantity)))}</span></p>
            }
                                 </div>}
                                </li>)
                            }) :
                              itemData.accessoryData.length>0 && this.state.warrantyInventory.includes(index)? 
                                (
                                <li className={`prodListWarranty prodList${outOfStock ? ' outOfStockList' : ''}`} key={`${index}-pro`}>
                                 <div className='prodImg'  checked={itemData.addWarrantySKU != '' ? true:false} 
                                //  onClick={this.checkboxWarranty.bind(this)}
                                 >
                                 
                                  <div>
                                  <input
                                    checked={itemData.addWarrantySKU != '' ? true:false} value={itemData.addWarrantySKU} 
                                    // onChange={this.checkboxWarranty.bind(this)}
                                    defaultChecked={itemData.addWarrantySKU != ''} name={itemData.partNumber} id={itemData.accessoryData[0].uniqueID} className='bundle-checkbox' type='checkbox' />
                                      <label  for={itemData.accessoryData[0].uniqueID}> 
                                      <img className='img' src={`${imagePrefix}${itemData.accessoryData[0].thumbnail}`}  alt={index} />
                                      </label>
                                  </div>
                                </div>
                                  <div className='prodDetails'>
                                    <h3 className='prodTitle'>{itemData.accessoryData[0].productName}</h3>
                                    <p className='subTitle'>({itemData.accessoryData[0].shortDescription})</p>
                                    { !itemData.accessoryData[0].freeGift ?
                                      !isMobile() && 
                    <> <div className="cartQty">
                      <label className="qytLabel">{!isMobile() ? "QUANTITY"  : 'QUANTITY: '}</label>
                      <div>&nbsp;&nbsp;&nbsp;&nbsp;{itemData.quantity}</div>
                      {/* <span></span> */}
                      <span className='price'>₹{Math.round(itemData.accessoryData[0].offerPrice*itemData.quantity)}</span>
                    </div></> : 
                                      <div className="cartQty">
                                        <label className="qytLabel">{!isMobile() ? "QUANTITY"  : 'QUANTITY: '}</label>
                                        <span>{itemData.quantity}</span>
                                        <span className='price'>{FREE}</span>
                                      </div>}
                                    <div className="add-warr-txt">Get extended warranty for protection against damages.</div>
                                    <button className="add-war-btn" disabled={this.state.warButton} onClick={()=>{this.checkboxWarranty(itemData.addWarrantySKU, itemData.partNumber, itemData.accessoryData[0].uniqueID, itemData.addWarrantySKU != ''? false:true)}}>{this.state.warButton ? 'Adding' :'ADD'} </button>
                                   {!itemData.accessoryData[0].freeGift ? 
                                      !isMobile() && <span className='shipping'>{SHIPPING_CHARGES} {1 ? `${FREE}` : itemData.accessoryData[0].shippingCharges}</span> : ""}
                                  </div>
                                  {!!isMobile() && !itemData.accessoryData[0].freeGift && <div className='quantityPrice'>
                                    <div className="cartQty">
                                      <label className="qytLabel">{!isMobile() ? "QUANTITY"  : 'QUANTITY: '}</label>
                                      <span>{itemData.quantity}</span>
                                    </div>
                                    <p className='price'>₹{Math.round(itemData.accessoryData[0].offerPrice*itemData.quantity)}</p>
                                  </div>}
                                </li>):<></>
                           }
                          </>
                        </>}
                    </>
			  )})}
              </ul>
            </div>
            <div className='orderSummary'>
              <div className='promotion'>
                {!isMobile() ?
                  <>
                    <p
						  className='promoMsg'
						  onClick={this.handleOnClick.bind(this)}
                    >
						  {DO_YOU_HAVE_COUPAN}<ExpandIcon className={this.state.showReply? 'open-arrow' : 'close-arrow'} width={25} height={25}  />
                    </p>
                    {cartData.promotionCode && cartData.promotionCode.length ?
						  <AppliedPromoCode
						    promoCode = {cartData.promotionCode}
						    getCartDetails={this.props.getCartDetails}
                cartCouponsBlock={this.state.cartCouponsBlock}
						  /> :
						  this.state.showReply && <PromoField
						    appliedPromoCode = {cartData.promotionCode}
						    orderID={cartData.orderSummary.orderID}
						    getCartDetails={this.props.getCartDetails}
                cartCouponsBlock={this.state.cartCouponsBlock}
                promocodeError={this.state.promocodeError}                
						  />}
                  </>:
                  cartData.promotionCode && cartData.promotionCode.length ?
                    <AppliedPromoCode
                      promoCode = {cartData.promotionCode}
                      getCartDetails={this.props.getCartDetails}
                      cartCouponsBlock={this.state.cartCouponsBlock}
                    /> :
                    <PromoField
                      appliedPromoCode = {cartData.promotionCode}
                      orderID={cartData.orderSummary.orderID}
                      getCartDetails={this.props.getCartDetails}
                      cartCouponsBlock={this.state.cartCouponsBlock}
                      promocodeError={this.state.promocodeError}
                      />
                }
                <GetCartPromo
                  appliedPromoCode = {cartData.promotionCode}
                  orderID={cartData.orderSummary.orderID}
                  getCartDetails={this.props.getCartDetails}
                />
              </div>
              <div className='summary summary-border-div'>
                <h2 className='title'>{ORDER_SUMMARY}</h2>
      
                <p className='cartTotal'>
                  <span className='info'>{CART_TOTAL}</span>
                  <span className='val'> ₹{formatPrice(Math.round(cartData.orderSummary.totalAmount === '' ? '0' : cartData.orderSummary.totalAmount))}</span>
                </p>
                {!!cartData.orderSummary.productDiscount &&
                <p className="prodDisc" style={{color: this.state.discount_color}}>
                  <span className='info'>{PRODUCT_DISCOUNT}</span>
                  <span className='val'>- ₹{formatPrice(Math.round(cartData.orderSummary.productDiscount))}</span>
                </p>
                }
                {!!cartData.orderSummary.orderDiscount &&
                <p className='orderDisc' style={{color: this.state.discount_color}}>
                  <span className='info'>{ORDER_DISCOUNT}</span>
                  <span className='val'>- ₹{formatPrice(Math.round(cartData.orderSummary.orderDiscount))}</span>
                </p>
                }
                {!!cartData.orderSummary.exchangeDiscount &&
                <p className='orderDisc' style={{color: this.state.discount_color}}>
                  <span className='info'>{EXCHANGE_DISCOUNT}</span>
                  <span className='val'>- ₹{formatPrice(Math.round(cartData.orderSummary.exchangeDiscount))}</span>
                </p>
                }
                {!!cartData.orderSummary.additionalDiscount && 
                <p className='orderDisc' style={{color: this.state.discount_color}}>
                  <span className='info'>{ADDITIONAL_DISCOUNT}</span>
                  <span className='val'>- ₹{formatPrice(Math.round(cartData.orderSummary.additionalDiscount))}</span>
                </p>
                }
                {!!cartData.orderSummary.shippingCharges == '0' ?
                  <p className='shipping'>
                    <span className='info'>{SHIPPING}</span>
                    <span className='val'>{FREE}{this.state.strikethroughText && this.state.strikethroughText!='' && '  '}<span className='strikethrough'>{this.state.strikethroughText && this.state.strikethroughText!='' && this.state.strikethroughText}</span></span>

                    <span className='shippingMsg'>{cartData.orderSummary.shippingMessage}</span>
                  </p>
                  :
                  <p className='shipping'>
                    <span className='info'>{SHIPPING}</span>
                    <span className='val'>-₹{formatPrice(Math.round(cartData.orderSummary.shippingCharges))}</span>
                  </p>
                }
              </div>
              <div className='summary'>
                
                  <EMIVal getCartDetails={this.props.getCartDetails} price={cartData.orderSummary.netAmount} />
                  {/* <EmiInfo price={cartData.orderSummary.netAmount}/> */}
                
                {!isMobile() ? <p className='totalAmt'>
                  <span className='totalPrice'> {TOTAL}</span>
                  <span className='val'>₹{formatPrice(Math.round(cartData.orderSummary.netAmount))}</span>
                  {/* <span className='savingText'>{YOU_SAVED} <span className='savedAmt'>₹{formatPrice(cartData.orderSummary.saving)}</span></span> */}
                </p>:''}
                <div>
                  <label className='whatsapp-text'><ContentEspot espotName="GI_CART_TermsANDCond" /></label>            
                </div>
                {!isMobile() ? (<a id='checkoutBtn' className={`btn btnCheckout ${disableCheckout ? 'disabled oos' : '' }`} 
                onClick={this.validateDealerCode.bind(this)}
                // href={!disableCheckout ? '/checkout' : ''}
                >{PROCEED_TO_CHECK_OUT}</a>)
                  :
                  (<div className="checkout-btn-floater">
                    <div className="total-amount"><div className="net-amount-box"><span className="total-amount-text">{TOTAL_AMOUNT}</span><span className="total-amount-number">₹{formatPrice(Math.round(cartData.orderSummary.netAmount))}</span></div></div>
                    <div className="proceed-btn"><a id='checkoutBtn' className={`btn-blackbg ${disableCheckout ? 'disabled oos' : '' }`} 
                    onClick={this.validateDealerCode.bind(this)}
                    // href={!disableCheckout ? '/checkout' : ''}
                    >{PROCEED_TO_CHECK_OUT}</a></div>
                  </div>)}
              </div>
            </div>
          </section>
          {/* {this.state.bundleLoad?  <Suspense fallback={<>...Loading</>}>
            <BundleMainCart updateCartFromBundle={this.updateCartFromBundle.bind(this)} bundlingData={this.state.bundlingData} />
          </Suspense>:<></>} */}

          <div className="dealer-relationship-div">
          {!isMobile() ?
          <div className="dealer-relationship-div-1">
          <div className="instore-employee-section">
          <OverlayTrigger id='dealerRelationShipDescOverlay'
              overlay={tooltipDealerRelationshipDesc} placement="right"
              style={{ width: 700, color: "red" }}
              > 
              <a>i</a>
          </OverlayTrigger>
                  <span className="instore-employee-section-text">{this.state.instoreEmployeeText}</span>
                </div>
          </div>
          :''}
              <div className="dealer-code-text-section">
                <div className={"dealer-code-text-section1 "+this.state.dealercodetextclassName}>
                    <input ref={this.textInput} class="form-control search-input dealer-code-input" id="searchInput" type="text" 
                    autocomplete="off" placeholder={this.state.instoreEmployeePlaceholder} 
                    value={this.state.dealerCode}
                    onChange={this.handleDealerCode}
                    />
                </div>
                {this.state.error?<p className="dealercode-error-msg">{this.state.error ?this.state.errorMsg:null}</p>:''}
                {this.state.dealerCodeValidationErrorMsg?<p className="dealercode-error-msg">{this.state.dealerCodeValidationErrorMsg}</p>:''}
              </div>

          </div>
 
          {/* <ContentEspot espotName = { 'GI_PIXEL_CART_BODY_END' } /> */}

        </>
        : <EmptyCart />
    )
  }
}



const mapStateToProps = state => {
  const stateObj = getReleventReduxState(state, 'cart');
  return {
    cartData: stateObj,
  };
};

const mapDispatchToProps = dispatch => ({
  getCartDetails: () => dispatch(getCartDetails()),
});

// const withConnect = connect(
//   (state) => ({
//     cartData: getReleventReduxState(state, 'cart')
//   }),
//   {
//     getCartDetails,
//   }
// );

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps,
);

const withReducer = injectReducer({ key: 'cart', reducer });
const withSaga = injectSaga({ key: 'cartContainer', saga });

export default compose(
  withReducer,
  withSaga,
  withConnect,
)(CartDetail);
